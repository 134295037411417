// Copyright (C) dātma, inc™ - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import { createRouter, createWebHistory } from 'vue-router'

import { store } from '@/store'
import { baseUrl, noBackend, isUx3 } from '@/settings'

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: store ? store.getters.mostRecentSessionUrlOrDashboard : '',
    meta: { requiresLogin: true },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '@/pages/Login/Login.vue'),
  },
  {
    path: '/user/:userName/session/:sessionId',
    name: 'Session',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "session" */ '@/pages/Session/Session.vue'),
    meta: { requiresLogin: true },
    props: true,
  },
  // {
  //   path: '/user/:userName/vrsession/:sessionId',
  //   name: 'VRSession',
  //   component: () => import(/* webpackChunkName: "vrsession" */ '@/pages/VRSession/VRSession.vue'),
  //   meta: { requiresLogin: true },
  //   props: true,
  // },
  {
    path: '/user/:userName/profile',
    name: 'Profile',
    component: () => import(/* webpackChunkName: "profile" */ '@/pages/Profile/Profile.vue'),
    meta: { requiresLogin: true, userNameMustMatchLogin: true },
  },
  {
    path: '/user/:userName/dashboard',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ '@/pages/Dashboard/Dashboard.vue'),
    meta: { requiresLogin: true, userNameMustMatchLogin: true },
  },
]

const tumorBoardRoutes = [
  {
    path: '/',
    name: 'Home',
    redirect: store ? '/tumor_board' : '',
    meta: { requiresLogin: true },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '@/pages/Login/Login.vue'),
  },
  {
    path: '/tumor_board',
    name: 'TumorBoard',
    component: () => import(/* webpackChunkName: "tumor_board" */ '@/pages/TumorBoard/TumorBoard.vue'),
    meta: { requiresLogin: true },
  },
]

const genRoutes = () => {
  const ux2Routes = noBackend ? routes.slice(0, routes.length - 1) : routes
  return isUx3 ? tumorBoardRoutes : ux2Routes
}

const router = createRouter({
  history: createWebHistory(baseUrl),
  routes: genRoutes(),
})

router.beforeEach((to, _, next) => {
  if (to.meta.requiresLogin === true && !store.getters.jwtIsValid(new Date())) {
    next(`/login?redirect_url=${encodeURIComponent(to.fullPath)}`)
    return
  }

  if (to.meta.userNameMustMatchLogin === true) {
    if (to.fullPath === store.getters.dashboardUrl || to.fullPath === store.getters.profileUrl) {
      next()
      return
    }

    if (to.fullPath.includes('/profile')) {
      next(store.getters.profileUrl)
      return
    }

    if (to.fullPath.includes('/dashboard')) {
      next(store.getters.dashboardUrl)
      return
    }
  }

  next()
})

export default router
